import {useSnackbar} from "notistack";
import React, {SyntheticEvent, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {jwtInfo} from "../../../network-requests/register";
import { getContactUsLink } from '../../../util/helpers';
import RegistrationLayout from '../layout/registration-layout';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  firstNameValidator,
  firstNameValidatorNoRegex,
  generateDays,
  generateMonths,
  generateYears, lastNameValidator,
  lastNameValidatorNoRegex
} from '../../../util/validator';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteValue,
  Card,
  createFilterOptions,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel, Link,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {RegInfoType, RegistrationPagesEnum} from '../../../contexts/registration';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {PasswordStrength} from '../../../components/password-strength/passwordStrength';
import {PasswordInfo} from '../../my-account/change-password/changePasswordTypes';
import {trimDoubleSpace} from '../../../util/string-util';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {GlobalContext} from '../../../contexts/global';
import {handleSubmit} from './4-validation-helpers';
import {PhoneNumbers} from '../../my-account/profile/profile';
import {updatePhoneNumbers, validateEditableFields} from '../../my-account/profile/helpers/helpers';
import {PhoneErrors} from "../../my-account/myAccountTypes";
import {removeDashesPhoneNumber} from '../../../util/phoneFormatter';
import AlertDialog from '../../../components/cancel-dialogue/cancelDialogue';

export type RegisterFields = {
  password: string,
  passwordHelperText: string,
  confirmPassword: string,
  confirmPasswordHelperText: string,
  fName: string,
  fNameHelperText: string,
  lName: string,
  lNameHelperText: string,
  receiveEmails: any,
  receiveEmailsHelperText: string,
  dobYear: any,
  dobMonth: any,
  dobDay: any,
  dobHelperText: string,
  showPass: string,
}

export type ValidationPageProps = {
  registerFields: RegisterFields
  setRegisterFields: React.Dispatch<React.SetStateAction<RegisterFields>>,
  phoneEditState: PhoneNumbers,
  setPhoneEditState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  formattedPhoneNumber: PhoneNumbers,
  setFormattedPhoneNumber: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  phoneEditErrors: PhoneErrors,
  setPhoneEditErrors: React.Dispatch<React.SetStateAction<PhoneErrors>>,
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  theme: string
}

export type Label = { label: string; value: string; }

const checkChoicesRegAlreadyComplete = async (
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  setAlreadyRegistered: React.Dispatch<React.SetStateAction<boolean>>,
  enqueueSnackbar: any,
  theme: string
) => {
  let r = await jwtInfo(registrationContext.jwt);
  if (r.status !== 200) {
    // 406 old token, error from gql
    if (r.status === 406) {
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        currentPage: RegistrationPagesEnum.VerifyEmailExpiredPage
      }));
    } else if (r.status === 423) {
      setAlreadyRegistered(true);
    } else {
      // unexpected error - more helpful for user
      r.text().then(msg => enqueueSnackbar(
        <>
          <Typography
            sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
            An unexpected error occurred. Please try again. If this issue persists please&nbsp;
            <Link
              href={getContactUsLink(theme)}
              target="_blank"
              className="link-white link-choices"
            >contact us
            </Link>
          </Typography>
        </>,
        { variant: 'error' }
      ));
    }
  }
}

/**
 * Page to collect user information like password, names, phone, bday, ect
 * @constructor
 */
const ValidationPage = (props: ValidationPageProps) => {
  const {
    registerFields,
    setRegisterFields,
    phoneEditState,
    setPhoneEditState,
    formattedPhoneNumber,
    setFormattedPhoneNumber,
    phoneEditErrors,
    setPhoneEditErrors,
    registrationContext,
    setRegistrationContext,
    theme
  } = props;
  const { globalContext } = useContext(GlobalContext);
  let { t, ready } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    newPassword: {
      input: registerFields.password || ''
    },
    oldPassword: {
      input: ''
    },
    confirm: {
      input: registerFields.confirmPassword || ''
    },
    weaknesses: [],
    helperText: '',
    allRequirementsMet: false,
    passwordStrengthInformation: {
      strengthMeterBarColor: 'error',
      strengthMeterText: 'weak'
    },
    passwordStrengthMeterValue: 1,
    warning: '',
    suggestions: []
  });
  const selectYears: Label[] = useMemo(() => generateYears(), []);
  const selectMonths: Label[] = useMemo(() => generateMonths(), []);
  const selectDays: Label[] = useMemo(() => generateDays(), []);

  // take url jwt and validate, if valid render this page with email populated
  useEffect(() => {
    const choicesAccountCheck = async () => {
      if (ready && registrationContext) {
        await checkChoicesRegAlreadyComplete(
          registrationContext,
          setRegistrationContext,
          setAlreadyRegistered,
          enqueueSnackbar,
          theme
        )
      }
      /* This flag technically generates the card and flags it to be sent to the customer
       * but in the Choices registration code in the customer accounts backend
       * we null the "mail this card" flag for choices registrations */
      if ( theme === "choices" || theme === "meinhardt" ) {
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          needMrCard: true
        }));
      }
    }
    choicesAccountCheck();
  }, [ready, registrationContext.jwt]);

  useEffect(() => {
    pushRouteChangeEvent('Register: ValidationPage');
    validateEditableFields(phoneEditState, setPhoneEditErrors, 'register');
    setRegisterFields((prev: any) => ({
      ...prev,
      fName: registrationContext.fName,
      lName: registrationContext.lName,
      dobYear: registrationContext.dob.split('-')[0],
      dobMonth: registrationContext.dob.split('-')[1],
      dobDay: registrationContext.dob.split('-')[2],
    }));
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      alternate1: registrationContext.mobilePhone,
      primary: registrationContext.homePhone
    }));
  }, []);

  useEffect(() => {
    validateEditableFields(phoneEditState, setPhoneEditErrors, 'register');
    updatePhoneNumbers(setFormattedPhoneNumber, phoneEditState);
  }, [phoneEditState]);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  if (alreadyRegistered) {
    return (
      <RegistrationLayout>
        <Typography variant="h2" component="h2" className={theme === "choices" ? "topHeaderChoices" : "topHeader"}>
          {t('REGISTER.CREATE_ACCOUNT.REGISTRATION_COMPLETE_HEADER')}
        </Typography>
        <div className="register-font">
          <Typography
            component="p"
            variant="body2"
            className="topText">
            {t('REGISTER.CREATE_ACCOUNT.REGISTRATION_COMPLETE')}
          </Typography>
        </div>
        <div className="centeredContainer account-steps-nav">
          <Button
            type="submit"
            variant="contained"
            className="primaryButtonDefault"
            onClick={() => {
              setRegistrationContext((prev: RegInfoType) => ({
                ...prev,
                currentPage: RegistrationPagesEnum.VerifyEmailPage,
              }));
              navigate('/');
            }}
          >{t('BUTTONS.LOGIN')}
          </Button>
        </div>
      </RegistrationLayout>
    )
  }

  return (
    <>
      <AlertDialog
        openDialogue={openDialogue}
        setOpenDialogue={setOpenDialogue}
        setRegistrationContext={setRegistrationContext}
        theme={theme}
      />
      <RegistrationLayout>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(
            event,
            passwordInfo,
            phoneEditState,
            phoneEditErrors,
            setRegistrationContext,
            setPhoneEditErrors,
            registerFields,
            setRegisterFields,
            globalContext,
            registrationContext.existingAccount,
            t
          )}
          className={globalContext.referer === 'choices' ? 'choicesWhiteBoxWide' : ''}
          noValidate>
          <div className="accountWrapper">
            <Typography
              variant="h2"
              component="h2"
              className={theme === "choices" ? "topHeaderChoices" : "topHeader"}
            >{t('REGISTER.HEADERS.CREATE_ACCOUNT')}
            </Typography>
            <Card className="wide-white-box steps">
              {theme !== 'choices' &&
                <div className="infoBar infoBarThin">
                  <div className="info">
                    <h3><strong>Step 2 / 3:</strong> Tell us about yourself</h3>
                  </div>
                </div>
              }
              <div className="centeredContainer">
                <div className="innerColumn">
                  <Grid container rowSpacing={2} columnSpacing={{ md: 5, lg: 5 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.PASSWORD')}</InputLabel>
                      <TextField
                        id="regPass"
                        className="fullWidth"
                        required
                        autoComplete="new-password"
                        type={registerFields.showPass ? 'text' : 'password'}
                        // autoFocus
                        // @ts-ignore
                        error={
                          registerFields.passwordHelperText !== '' ||
                          passwordInfo.warning ||
                          passwordInfo.suggestions.length > 0 && passwordInfo.newPassword.input.length > 0
                        }
                        value={registerFields.password}
                        helperText={registerFields.passwordHelperText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setRegisterFields((prev: any) => ({
                            ...prev,
                            password: event.target.value,
                            passwordHelperText: event.target.value.length > 0 ? '' : t('VALIDATOR.PASSWORD.REQUIRED')
                          }));
                          if (registerFields.confirmPasswordHelperText) {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              confirmPasswordHelperText: event.target.value === passwordInfo.confirm.input ? '' :
                                t('VALIDATOR.PASSWORD.NOT_MATCH')
                            }));
                          }
                          setPasswordInfo((prev: PasswordInfo) => ({
                            ...prev,
                            newPassword: {
                              ...prev.newPassword,
                              input: event.target.value
                            }
                          }));
                        }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setRegisterFields((prev: any) => ({
                                    ...prev,
                                    showPass: !registerFields.showPass
                                  }));
                                }}
                                edge="end"
                              >
                                {registerFields.showPass ? <VisibilityOff/> : <Visibility/>}
                              </IconButton>
                            </InputAdornment>
                        }}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel
                        required
                        className="inputLabel">{t('REGISTER.VALIDATION.CONFIRM_PASS')}
                      </InputLabel>
                      <TextField
                        className="fullWidth"
                        required
                        autoComplete="new-password"
                        type={registerFields.showPass ? 'text' : 'password'}
                        // @ts-ignore
                        error={registerFields.confirmPasswordHelperText !== ''}
                        value={registerFields.confirmPassword}
                        helperText={registerFields.confirmPasswordHelperText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setRegisterFields((prev: any) => ({
                            ...prev,
                            confirmPassword: event.target.value,
                            confirmPasswordHelperText: event.target.value === registerFields.password ? '' :
                              t('VALIDATOR.PASSWORD.NOT_MATCH')
                          }));
                          setPasswordInfo((prev: PasswordInfo) => ({
                            ...prev,
                            confirm: {
                              ...prev.oldPassword,
                              input: event.target.value
                            }
                          }));
                        }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setRegisterFields((prev: any) => ({
                                    ...prev,
                                    showPass: !registerFields.showPass
                                  }));
                                }}
                                edge="end"
                              >
                                {registerFields.showPass ? <VisibilityOff/> : <Visibility/>}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <PasswordStrength
                        passwordInfo={passwordInfo}
                        setPasswordInfo={setPasswordInfo}
                        pageType={'Register'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.F_NAME')}</InputLabel>
                      <TextField
                        className="fullWidth"
                        required
                        disabled={registrationContext.existingAccount && registrationContext.fName !== ''}
                        autoComplete="given-name"
                        error={registerFields.fNameHelperText !== ''}
                        helperText={registerFields.fNameHelperText}
                        value={registerFields.fName}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setRegisterFields((prev: any) => ({
                            ...prev,
                            fName: trimDoubleSpace(event.target.value),
                            fNameHelperText: (registrationContext.existingAccount && registrationContext.fName !== '') ? firstNameValidatorNoRegex(trimDoubleSpace(event.target.value), t) : firstNameValidator(trimDoubleSpace(event.target.value), t)
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.L_NAME')}</InputLabel>
                      <TextField
                        className="fullWidth"
                        required
                        disabled={registrationContext.existingAccount && registrationContext.lName !== ''}
                        autoComplete="family-name"
                        value={registerFields.lName}
                        error={registerFields.lNameHelperText !== ''}
                        helperText={registerFields.lNameHelperText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setRegisterFields((prev: any) => ({
                            ...prev,
                            lName: trimDoubleSpace(event.target.value),
                            lNameHelperText: (registrationContext.existingAccount && registrationContext.lName !== '') ? lastNameValidatorNoRegex(trimDoubleSpace(event.target.value), t) : lastNameValidator(trimDoubleSpace(event.target.value), t)
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel className="inputLabel">{t('Phone')}*</InputLabel>
                      <TextField
                        className="fullWidth"
                        type="tel"
                        autoComplete="tel"
                        // @ts-ignore
                        error={
                          phoneEditErrors.primaryIncorrectlyEntered.value ||
                          phoneEditErrors.primaryEmpty.value
                        }
                        helperText={
                          phoneEditErrors.primaryIncorrectlyEntered.value ||
                          phoneEditErrors.primaryEmpty.value
                        }
                        inputProps={{ maxLength: 12 }}
                        value={formattedPhoneNumber.primary}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setPhoneEditState((prev: PhoneNumbers) => ({
                            ...prev,
                            primary: removeDashesPhoneNumber(event.target.value)
                          }));
                        }}
                      />
                    </Grid>
                    <Grid className="FormLabelWrapper" item xs={12} sm={12} md={12} lg={12}>
                      <FormLabel required className="FormLabel">DATE OF BIRTH</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <InputLabel required className="inputLabel">Year</InputLabel>
                      <Autocomplete
                        className="fullWidth"
                        disablePortal
                        options={selectYears}
                        defaultValue={registrationContext.dob.split('-')[0]}
                        autoHighlight
                        autoSelect
                        isOptionEqualToValue={(option, value) => {
                          if (typeof value === 'string') {
                            return option.value === value;
                          }
                          return option.value == value.value;
                        }}
                        onChange={(event: SyntheticEvent, select: AutocompleteValue<any, any, any, any>) => {
                          if (select) {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              dobYear: select.value,
                              dobHelperText: ''
                            }));
                          } else {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              dobYear: null,
                              dobHelperText: ''
                            }));
                          }
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) =>
                          <TextField
                            error={registerFields.dobHelperText !== ''}
                            {...params}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <InputLabel required className="inputLabel">Month</InputLabel>
                      <Autocomplete
                        className="fullWidth"
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={selectMonths}
                        defaultValue={registrationContext.dob.split('-')[1]}
                        isOptionEqualToValue={(option, value) => {
                          if (typeof value === 'string') {
                            return option.value === value;
                          }
                          return option.value == value.value;
                        }}
                        filterOptions={createFilterOptions({
                          matchFrom: 'any',
                          stringify: (option) => option.value + ' ' + option.label
                        })}
                        onChange={(event: SyntheticEvent, select: AutocompleteValue<any, any, any, any>) => {
                          if (select) {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              dobMonth: select.value,
                              dobHelperText: ''
                            }));
                          } else {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              dobMonth: null,
                              dobHelperText: ''
                            }));
                          }
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) =>
                          <TextField
                            error={registerFields.dobHelperText !== ''}
                            {...params}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <InputLabel required className="inputLabel">Day</InputLabel>
                      <Autocomplete
                        className="fullWidth"
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={selectDays}
                        defaultValue={registrationContext.dob.split('-')[2]}
                        isOptionEqualToValue={(option, value) => {
                          if (typeof value === 'string') {
                            return option.value === value;
                          }
                          return option.value == value.value;
                        }}
                        onChange={(event: SyntheticEvent, select: AutocompleteValue<any, any, any, any>) => {
                          if (select) {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              dobDay: select.value,
                              dobHelperText: ''
                            }));
                          } else {
                            setRegisterFields((prev: any) => ({
                              ...prev,
                              dobDay: null,
                              dobHelperText: ''
                            }));
                          }
                        }}
                        filterOptions={createFilterOptions({
                          matchFrom: 'any',
                          stringify: (option) => option.value + ' ' + option.label
                        })}
                        renderInput={(params: AutocompleteRenderInputParams) =>
                          <TextField
                            error={registerFields.dobHelperText !== ''}
                            {...params}
                          />
                        }
                      />
                    </Grid>
                    {registerFields.dobHelperText !== '' &&
                      <FormHelperText className={'dobHelper'}>{registerFields.dobHelperText}</FormHelperText>
                    }
                    {theme !== 'choices' && theme !== 'meinhardt' &&
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="choicesHide">
                          <Typography
                            variant="h2"
                            className="hrHeading"
                            component="h2">{t('REGISTER.HEADERS.EMAIL_PREF')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} className="choicesHide">
                          <p className="pMargin">{t('REGISTER.VALIDATION.SUBSCRIBE_TO_EMAILS')}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} className="choicesHide">
                          <FormControl error variant="standard">
                            <ToggleButtonGroup
                              className="YesNo"
                              value={registerFields.receiveEmails}
                              exclusive
                              onChange={(e: React.MouseEvent<HTMLElement, MouseEvent>, v) => {
                                if (v === null) {
                                  return;
                                }
                                setRegisterFields((prev: any) => ({
                                  ...prev,
                                  receiveEmails: v,
                                  receiveEmailsHelperText: ''
                                }));
                              }}>
                              <ToggleButton value={true}>{t('REGISTER.VALIDATION.YES')}</ToggleButton>
                              <ToggleButton value={false}>{t('REGISTER.VALIDATION.NO')}</ToggleButton>
                            </ToggleButtonGroup>
                            <FormHelperText>{registerFields.receiveEmailsHelperText}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </>
                    }
                  </Grid>
                </div>
              </div>
              <div className="account-steps-nav-cancel flexCenter">
                <Button
                  id="nextStepBtn"
                  type="submit"
                  variant="contained"
                  className="primaryButtonDefault">{t('BUTTONS.NEXT')}</Button>
                <Button
                  variant="outlined"
                  className="cancelButtonDefault"
                  onClick={() => {
                    setOpenDialogue(true);
                  }}
                >{t('BUTTONS.CANCEL')}</Button>
              </div>
            </Card>
          </div>
        </form>
      </RegistrationLayout>
    </>
  );
};

export default ValidationPage;