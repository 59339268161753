export const RECAPTCHA_THRESHOLD = parseFloat(process.env.REACT_APP_RECAPTCHA_THRESHOLD);
export const RECAPTCHA_V3_KEY = process.env.REACT_APP_RECAPTCHA_V3_KEY;
export const RECAPTCHA_V2_KEY = process.env.REACT_APP_RECAPTCHA_V2_KEY;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const AM_URL = process.env.REACT_APP_AM_URL;
export const MICROSERVICE_URL = process.env.REACT_APP_MICROSERVICE_URL;
export const CANADA_POST_SEARCH_URL = process.env.REACT_APP_CANADA_POST_SEARCH_URL;
export const CANADA_POST_DETAILED_ADDRESS_URL = process.env.REACT_APP_CANADA_POST_DETAILED_ADDRESS_URL;
export const CANADA_POST_KEY = process.env.REACT_APP_CANADA_POST_KEY;
export const IS_PRODUCTION = process.env.NODE_ENV && process.env.NODE_ENV === 'production';
export const CLIENT_ID = 'forgerock-sdk-MoreRewardsOAuth'; //todo we should not be assuming the name of the local storage item the sdk writes to
export const PROJECT_PHASE = parseInt(process.env.REACT_APP_PROJECT_PHASE);
export const LINK_SOF = process.env.REACT_APP_LINK_SOF;
export const LINK_SOF_IOS_APP = process.env.REACT_APP_LINK_SOF_IOS_APP;
export const LINK_UF_IOS_APP = process.env.REACT_APP_LINK_UF_IOS_APP;
export const LINK_PSF_IOS_APP = process.env.REACT_APP_LINK_PSF_IOS_APP;
export const LINK_CHOICES_IOS_APP = process.env.REACT_APP_LINK_CHOICES_IOS_APP;
export const LINK_SOF_ANDROID_APP = process.env.REACT_APP_LINK_SOF_ANDROID_APP;
export const LINK_UF_ANDROID_APP = process.env.REACT_APP_LINK_UF_ANDROID_APP;
export const LINK_PSF_ANDROID_APP = process.env.REACT_APP_LINK_PSF_ANDROID_APP;
export const LINK_CHOICES_ANDROID_APP = process.env.REACT_APP_LINK_CHOICES_ANDROID_APP;
export const LINK_UF = process.env.REACT_APP_LINK_UF;
export const LINK_PSF = process.env.REACT_APP_LINK_PSF;
export const LINK_QF = process.env.REACT_APP_LINK_QF;
export const LINK_MR = process.env.REACT_APP_LINK_MR;
export const LINK_MR_OFFERS = process.env.REACT_APP_LINK_MR_OFFERS;
export const LINK_MR_TRAVEL = process.env.REACT_APP_LINK_MR_TRAVEL;
export const LINK_CHOICES = process.env.REACT_APP_LINK_CHOICES;
export const LINK_CHOICES_PROD = process.env.REACT_APP_LINK_CHOICES_PROD;
export const LINK_MEINHARDT = process.env.REACT_APP_LINK_MEINHARDT;
export const LINK_MEINHARDT_PROD = process.env.REACT_APP_LINK_MEINHARDT_PROD;
export const REACT_APP_LINK_SOF_PROD = process.env.REACT_APP_LINK_SOF_PROD;
export const REACT_APP_LINK_UF_PROD = process.env.REACT_APP_LINK_UF_PROD;
export const REACT_APP_LINK_PSF_PROD = process.env.REACT_APP_LINK_PSF_PROD;
export const REACT_APP_LINK_MR_PROD = process.env.REACT_APP_LINK_MR_PROD;
export const LINK_MR_DONE = process.env.REACT_APP_LINK_MR_DONE ;
export const LINK_MY_OFFERS = process.env.REACT_APP_LINK_MY_OFFERS;
export const LINK_MR_OFFERS_ENV_PREFIX = process.env.REACT_APP_LINK_MR_ENV_PREFIX;
export const LINK_FAQ = process.env.REACT_APP_LINK_FAQ;
export const LINK_FAQ_LOGIN_PAGE = process.env.REACT_APP_LINK_FAQ_LOGIN_PAGE;
export const REACT_APP_LINK_MY_OFFERS_PROD = process.env.REACT_APP_LINK_MY_OFFERS_PROD;
export const LINK_CONTACT_US = process.env.REACT_APP_LINK_CONTACT_US;
export const LINK_CONTACT_US_CHOICES = process.env.REACT_APP_LINK_CONTACT_US_CHOICES;
export const LINK_CONTACT_US_MEINHARDT = process.env.REACT_APP_LINK_CONTACT_US_MEINHARDT;
export const LINK_MR_WIN_FREE_GROCERIES = process.env.REACT_APP_LINK_MR_WIN_FREE_GROCERIES;
export const LINK_LOST_CARD = process.env.REACT_APP_LINK_LOST_CARD;
export const LINK_TERMS_AND_CONDITIONS = process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS;
export const LINK_DISCLAIMER = process.env.REACT_APP_LINK_DISCLAIMER;
export const LINK_TERMS_AND_CONDITIONS_CHOICES = process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS_CHOICES;
export const LINK_TERMS_AND_CONDITIONS_MEINHARDT = process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS_MEINHARDT;
export const PRIVACY_POLICY_CHOICES = process.env.REACT_APP_LINK_PRIVACY_POLICY_CHOICES;
export const PRIVACY_POLICY_MEINHARDT = process.env.REACT_APP_LINK_PRIVACY_POLICY_MEINHARDT;
export const LINK_TRAVEL = process.env.REACT_APP_LINK_TRAVEL;
export const RELAY_STATE = process.env.REACT_APP_RELAY_STATE;
export const RELAY_STATE_SOF = process.env.REACT_APP_RELAY_STATE_SOF;
export const RELAY_STATE_UF = process.env.REACT_APP_RELAY_STATE_UF;
export const RELAY_STATE_PSF = process.env.REACT_APP_RELAY_STATE_PSF;
export const RELAY_STATE_CHOICES = process.env.REACT_APP_RELAY_STATE_CHOICES;
export const RELAY_STATE_MEINHARDT = process.env.REACT_APP_RELAY_STATE_MEINHARDT;
export const RELAY_STATE_LOGIN_PAGE = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE;
export const RELAY_STATE_REACT_APP_CHOICES = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE_CHOICES;
export const RELAY_STATE_REACT_APP_MEINHARDT = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE_MEINHARDT;
export const RELAY_STATE_REACT_APP_UF = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE_UF;
export const RELAY_STATE_REACT_APP_SOF = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE_SOF;
export const RELAY_STATE_REACT_APP_PSF = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE_PSF;
export const RELAY_STATE_REACT_APP_MR = process.env.REACT_APP_RELAY_STATE_LOGIN_PAGE_MR;
export const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY;
export const PRIVACY_POLICY_PFG = process.env.REACT_APP_PRIVACY_POLICY_PFG
export const LINK_MRID = process.env.REACT_APP_LINK_MRID;
export const LINK_PATTISON_FOOD_GROUP = process.env.REACT_APP_LINK_PATTISON_FOOD_GROUP;
export const PRIMARY_TYPE_CODE = 1; // no magic numbers :))
export const ALTERNATE_1_TYPE_CODE = 2;
export const ALTERNATE_2_TYPE_CODE = 3;
export const EMAIL_TYPE_CODE = 5;
export const EMAIL_SUB_TYPE_MR = 1;
export const EMAIL_SUB_TYPE_SPECIAL_OFFERS_MEAL_PREP = 2;
export const EMAIL_SUB_TYPE_SURVEYS = 3;
export const EMAIL_SUB_TYPE_FLYER_SPECIALS = 4;
export const EMAIL_SUB_TYPE_UF_SPECIALS = 5;
export const EMAIL_SUB_TYPE_PSF_SPECIALS = 6;
export const EMAIL_SUB_TYPE_QF_SPECIALS = 7;
export const SP_ENTITY_ID_CHOICES = process.env.REACT_APP_SP_ENTITY_ID_CHOICES;
export const SP_ENTITY_ID_SOF = process.env.REACT_APP_SP_ENTITY_ID_SOF;
export const SP_ENTITY_ID_PSF = process.env.REACT_APP_SP_ENTITY_ID_PSF;
export const SP_ENTITY_ID_UF = process.env.REACT_APP_SP_ENTITY_ID_UF;
export const SP_ENTITY_ID_MR = process.env.REACT_APP_SP_ENTITY_ID_MR;
export const OLDEST_YEAR_FROM_DATE = 133; // Equal to 120 results
export const EARLIEST_YEAR_FROM_DATE = 14; // No squeakers allowed
export const MR_APP_REDIRECT_URI = process.env.REACT_APP_MR_APP_REDIRECT_URI;
export const TOKEN_REVOKE_URL = process.env.REACT_APP_TOKEN_REVOKE_URL;
export const DAYS_IN_MONTH = 31;
export const REDIRECT_TIMEOUT: number = 15000;
export const UN_PW_INVALID = 'Username or Password is invalid.';
export const LOGIN_FAILURE = 'Login failure';
export const ACCOUNT_DISABLED = 'Account is locked, please wait 15 minutes.'
export const ACCOUNT_NO_CARD = 'Account has no active card';
export const ACCOUNT_NEEDS_RESET_PW = 'Account must reset password.';
export const SESSION_HAS_TIMED_OUT: string = 'Session has timed out';
export const ONE_TIME_FLAG_EDIT_FAIL = 'Error updating one time Name edit property on profile';
export const INVALID_BIOMETRIC_PW: string = 'Invalid Biometric Password';
export const HTTP_STATUS_TOO_MANY_REQUESTS: number = 429;
export const HTTP_STATUS_OK: number = 200;
export const CONFIRMATION_CALLBACK_INPUT_TRUE: number = 0;
export const CONFIRMATION_CALLBACK_INPUT_FALSE: number = 1;
export const PASSWORD_INPUT_FIELD: number = 1;
export const BIOMETRIC_INPUT_FIELD: number = 2;
export const NAME_INPUT_FIELD: number = 0;
export const INCORRECT_WEB_AUTH_STEP_TYPE: string = 'Error: unexpected error during biometric registration flow';
export const BIOMETRIC_REGISTER_DEVICE: string = 'Biometric Register Device';
export const SIGN_IN_BIOMETRICS: string = 'Sign in with fingerprint';
export const SIGN_IN_BIOMETRICS_IOS: string = 'Face ID / Touch ID';
export const LOGIN: string = 'Login';
export const REGISTER_BIOMETRICS_DEVICE: string = 'Enable fingerprint';
export const REGISTER_BIOMETRICS_DEVICE_IOS: string = 'Enable Face ID / Touch ID';
/**
 * This section must be consistent with microservice regex in src > util > validation
 */
export const EMAIL_REGEX = /(?=.{2,100}$)(^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[(2([0-4]\d|5[0-5])|1?\d{1,2})(\\.(2([0-4]\d|5[0-5])|1?\d{1,2})){3} \])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
export const MORE_REWARDS_CARD_REGEX = /^48[0-9]{9}$/;
export const MORE_REWARDS_AC_REGEX = /^[A-Za-z0-9]{6}$/;
export const PASSWORD_REGEX = /^[A-Za-z0-9()!-.,;: !{}?"'@#$%^&*-_=+\[\]<>/|]{6,1024}$/;
export const PHONE_REGEX = /^[0-9]{10}$/; // nicks old super awesome phone reg -> /^(?:\+?([0-9])[ -])?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4}$)/;
export const NAME_REGEX = /^[A-Za-z \-&]{1,40}$/;
export const ADDRESS_REGEX = /^[A-Za-z-\s#' \-.,0-9]{3,50}$/;
export const CITY_REGEX = /^[A-Za-z-\s#'! \-.,0-9]{2,32}$/;
export const POSTAL_CODE_REGEX = /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[a-zA-Z]{1}\d{1}[a-zA-Z]{1}\d{1}$/;
// export const COUNTRY_REGEX = /[a-zA-Z ]{2,30}/; //predefined list
// export const PROVENCE_REGEX = /[a-zA-Z ]{2,30}/; //predefined list
export const DATE_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
export const JWT_REGEX = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;
export const ONE_TIME_PASS_REGEX = /^[0-9]{6}$/;
export const DID_NOT_ACCEPT_TERMS_CONDITIONS: string = 'Oops! You must accept our terms and conditions to proceed with biometric device registration.';
export const UNEXPECTED_ERROR_DEVICE_REG: string = 'Oops! Unexpected error with registering your device, please try again.';
export const UNEXPECTED_ERROR_BIOMETRIC_PASSWORD: string = 'Oops! Looks like there was an issue with submitting your password to register your device, please try again.'
export const UNEXPECTED_ERROR_BIOMETRIC_FLOW: string = 'Oops! Looks like there was an unexpected error when trying to login your device with biometric, please try again.'
/**
 * End of regex section
 */

/**
 * This section contains text for digital card registration pages
 */
export const DIGITAL_CARD_REGISTRATION_TEXT: string = 'Enter your email address, first name, and last name, and we\'ll help you start your journey as a More Rewards member right away.';
export const DIGITAL_CARD_REGISTRATION_BULLET_INFO_ONE: string = 'You’ll instantly see details on how to proceed on-screen.';
export const DIGITAL_CARD_REGISTRATION_BULLET_INFO_TWO: string = 'We’ll email you a secure link to complete your registration.';
export const DIGITAL_CARD_REGISTRATION_EMAIL_TAKEN: string = 'This email is already in use.';
export const DIGITAL_CARD_REGISTRATION_EMAIL_ERROR: string = 'Something went wrong verifying the email.';
export const DIGITAL_CARD_REGISTRATION_LOGIN_LINK: string = 'Log In';

/**
 * End of digital card registration text
 */