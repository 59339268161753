import React, {FormEvent, useContext, useState, useEffect} from "react";
import {handleDigitalSubmit} from "./helpers";
import Typography from "@mui/material/Typography";
import {InputLabel} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  firstNameValidator,
  firstNameValidatorNoRegex, lastNameValidator,
  lastNameValidatorNoRegex
} from "../../../util/validator";
import Button from "@mui/material/Button";
import {handleThemeRedirection} from "../../my-account/myAccountHelpers";
import {Box} from "@mui/system";
import Link from "@mui/material/Link";
import RegistrationLayout from "../layout/registration-layout";
import {useNavigate} from "react-router-dom";
import {GlobalContext} from "../../../contexts/global";
import {useSnackbar, SnackbarProvider} from "notistack";
import {useTranslation, Trans} from "react-i18next";
import {RegInfoType, RegistrationPagesEnum} from "../../../contexts/registration";
import {trimDoubleSpace} from "../../../util/string-util";
import {CircularLoading} from "../../../components/loading/circular-loading";
import './digital-verify-email.scss'
import EmailController from '../controllers/emailController';
import {
  DIGITAL_CARD_REGISTRATION_TEXT,
  DIGITAL_CARD_REGISTRATION_BULLET_INFO_ONE,
  DIGITAL_CARD_REGISTRATION_BULLET_INFO_TWO
} from "../../../util/constants";


export type DigitalRegisterFields = {
  email: string ,
  emailHelperText: string | React.ReactNode,
  fName: string,
  fNameHelperText: string,
  lName: string,
  lNameHelperText: string,
}

export type DigitalValidationPageProps = {
  digitalRegisterFields: DigitalRegisterFields
  setDigitalRegisterFields: React.Dispatch<React.SetStateAction<DigitalRegisterFields>>,
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  theme: string
}
/**
 * This object represents the validation status for checking email availability (email exists?)
 * The object is continuously updated on field input changes to the email field.
 * isValidating: { boolean } currently in async call
 * available: { boolean } email is available
 * error: { boolean } error checking database for email
 * isValid { boolean } email is a valid 2-100 character email
 */
export type ValidationStatus = {
  isValidating: boolean;
  available: boolean;
  error: boolean;
  isValid: boolean;
};

/**
 * This Page Component builds and displays a form for the new digital card signup/registration. Here customers navigate to
 * https://account.morerewards.ca/register-digital to sign up as POS. Customers enter in their email, first name, last name
 * and subsequently will kickoff account registreation & creation and immediately receive a digital More Rewards Card and
 * activation code for use right away in stores.
 * @param props
 * @constructor
 */
const DigitalVerifyEmailPage = (props: DigitalValidationPageProps) => {
  const {
    digitalRegisterFields,
    setDigitalRegisterFields,
    registrationContext,
    setRegistrationContext,
    theme
  } = props;
  const navigate = useNavigate();
  const { globalContext, } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>({
    isValidating: false,
    available: false,
    error: false,
    isValid: false,
  });
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>
  (
    firstNameValid
    && lastNameValid
    && validationStatus.isValid
    && validationStatus.available
  );

  useEffect( () =>{
    setRegistrationContext( (prev:RegInfoType) => ({
      ...prev,
      currentPage:RegistrationPagesEnum.DigitalRegistrationPage
    }))
  }, [])

  useEffect( () => {
    const isFormInvalid = validationStatus.available && validationStatus.isValid && firstNameValid && lastNameValid
    setFormIsValid(!isFormInvalid)
  }, [validationStatus, firstNameValid, lastNameValid])

  return(
    <>
    { loading ?
      <div className="loadingProfile digital">
        <Box>
          <CircularLoading  theme={theme}/>
        </Box>
      </div>
      :
      <RegistrationLayout>
        <form
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            setLoading(true);
            handleDigitalSubmit(
              event,
              digitalRegisterFields.email,
              digitalRegisterFields.fName,
              digitalRegisterFields.lName,
              setRegistrationContext,
              registrationContext,
              enqueueSnackbar,
              t,
              theme,
              navigate,
              setLoading
            )
          }
          }
          noValidate
        >
          <div className='topTextRegister'>
            <Typography
              component="p"
              variant="body2"
              className="topTextRegister digitalRegister">
              {DIGITAL_CARD_REGISTRATION_TEXT}
            </Typography>
            <div className="digital-details-container bottomTextRegister">
              <ol className='digital-details'>
                <li>{DIGITAL_CARD_REGISTRATION_BULLET_INFO_ONE}</li>
                <li>{DIGITAL_CARD_REGISTRATION_BULLET_INFO_TWO}</li>
              </ol>
            </div>
          </div>
          <div className="centeredContainer">
            <div className="innerColumn">
              <div className="noBG-form">
                <InputLabel required
                            className="inputLabel inputLabelWhite digital-email-label">EMAIL</InputLabel>
                <EmailController
                  digitalRegisterFields={digitalRegisterFields}
                  setDigitalRegisterFields={setDigitalRegisterFields}
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                />
                <InputLabel required className="inputLabel inputLabelWhite">{t('FIRST NAME')}</InputLabel>
                <TextField
                  className="fullWidth"
                  required
                  id="regFName"
                  name="FName"
                  autoComplete="given-name"
                  error={digitalRegisterFields.fNameHelperText !== ''}
                  helperText={digitalRegisterFields.fNameHelperText}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setDigitalRegisterFields((prev: any) => ({
                      ...prev,
                      fName: trimDoubleSpace(event.target.value),
                      fNameHelperText: (registrationContext.existingAccount && registrationContext.fName !== '') ? firstNameValidatorNoRegex(trimDoubleSpace(event.target.value), t) : firstNameValidator(trimDoubleSpace(event.target.value), t)
                    }));
                    setFirstNameValid(firstNameValidatorNoRegex(trimDoubleSpace(event.target.value), t) == '' && firstNameValidator(trimDoubleSpace(event.target.value), t) == '');
                  }}
                  inputProps={{
                    'aria-label': 'First Name text field',
                  }}
                />
                <InputLabel required className="inputLabel inputLabelWhite">{t('LAST NAME')}</InputLabel>
                <TextField
                  className="fullWidth"
                  required
                  id="regLName"
                  name="LName"
                  autoComplete="family-name"
                  error={digitalRegisterFields.lNameHelperText !== ''}
                  helperText={digitalRegisterFields.lNameHelperText}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setDigitalRegisterFields((prev: any) => ({
                      ...prev,
                      lName: trimDoubleSpace(event.target.value),
                      lNameHelperText: (registrationContext.existingAccount && registrationContext.lName !== '') ? lastNameValidatorNoRegex(trimDoubleSpace(event.target.value), t) : lastNameValidator(trimDoubleSpace(event.target.value), t)
                    }));
                    setLastNameValid(firstNameValidatorNoRegex(trimDoubleSpace(event.target.value), t) == '' && firstNameValidator(trimDoubleSpace(event.target.value), t) == '');
                  }}
                  inputProps={{
                    'aria-label': 'Last Name text field',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="centeredContainer account-steps-nav-cancel">
            <Button
              type="submit"
              variant="contained"
              className="primaryButtonDefault"
              id="nextStepBtn"
              disabled={formIsValid}
            >{t('BUTTONS.SUBMIT')}</Button>
            <Button
              onClick={() => handleThemeRedirection(theme, globalContext.trafficType)}
              variant="contained"
              className="cancelButtonDefault"
              id="nextStepBtn"
              // disabled={formIsValid}   // cancel button: does this need to be valid? -JP
            >{t('Cancel')}</Button>
          </div>
          <Box className="registerBox">
            <div className="registerLink">
              Already a member?
              <Link className="link-yellow link-choices" onClick={() => navigate('/')}>
                Login
              </Link>
            </div>
          </Box>
        </form>
      </RegistrationLayout>
    }
    </>
  )
}
export default DigitalVerifyEmailPage