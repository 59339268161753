import {MICROSERVICE_URL} from '../util/constants';
import {RegInfoType} from '../contexts/registration';
import {removeSpacePostalCode} from "../pages/my-account/addresses/helpers/postalCodeFormatter";
import {handleServiceUnavailable} from "./resources";

export type AutoFillFormData = {
  "fName": string | null,
  "lName": string | null,
  "addressLine1": string | null,
  "addressLine2": string | null,
  "city": string | null,
  "postalCode": string | null,
  "countryName": string | null,
  "province": string | null,
  "mobilePhone": string | null,
  "workPhone": string | null,
  "homePhone": string | null,
  "dob": string | null
}

export type Store = {
  locationDesc: string
  storeID: number
  vendorCode?: number
}

export type SecurityQuestionsResponseType = {
  firstNames?: null | Array<string>
  lastNames?: null | Array<string>
  postalCodes?: null | Array<string>
  stores?: null | Store[]
}

export type CompleteAccountResponseType = {
  publicReference: number,
  wallet: {
    googleJWT: string | null,
    appleFile: string | null
  },
  card: {
    number: string
    ac: string
  }
}

export type CompleteVerifyEmailResponseType = {
  customerId?: any
  success?: boolean
  email?: string
  actionId?: number
  moreRewardsNumber?: any //could be null dawg
}

/**
 * Sends an email to provided email
 * Returns server response status
 * @param email
 * @param theme
 */
export const sendVerifyEmail = (email: string, theme: string): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/send-verify-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'email': email.trim(), 'banner': theme })
  }).then((r: Response) => handleServiceUnavailable(r));
};

/**
 * Takes jwt provided in email and returns data from jwt
 * @param jwt
 */
export const jwtInfo = (jwt: string): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/complete-verify-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'jwt': jwt })
  }).then((r: Response) => handleServiceUnavailable(r));
};

/**
 *
 * @param jwt
 * @param mrCard
 * @param mrAC
 */
export const validateCard = (jwt: string, mrCard: string, mrAC: string): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/validate-card`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'jwt': jwt, 'moreRewardsNumber': mrCard, 'activationCode': mrAC })
  }).then((r: Response) => handleServiceUnavailable(r));
};

/**
 * Takes a more rewards number and returns security questions about account
 * @param jwt
 */
export const securityQuestions = (jwt: string): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/security-questions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'jwt': jwt })
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const validateSecurityQuestions = (
  jwt: string,
  fName: string,
  lName: string,
  postalCode: string,
  lastStoreId: string
): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/validate-security-questions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'jwt': jwt,
      'firstName': fName ? fName : undefined,
      'lastName': lName ? lName : undefined,
      'postalCode': postalCode ? removeSpacePostalCode(postalCode) : undefined,
      'lastStoreId': lastStoreId ? lastStoreId : undefined
    })
  }).then((r: Response) => handleServiceUnavailable(r));
};
export const autoFillForm = (jwt: string): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/auto-fill-form`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'jwt': jwt
    })
  }).then((r: Response) => handleServiceUnavailable(r));
};


export const createAccount = (
  regContext: RegInfoType,
  theme: string
): Promise<Response> => {
  // trim some fields before sending data
  regContext.addressLine1 = regContext.addressLine1.trim();
  regContext.addressLine2 = (regContext.addressLine2 !== null) ? regContext.addressLine2.trim() : null;
  regContext.city = regContext.city.trim();
  regContext.fName = regContext.fName.trim();
  regContext.lName = regContext.lName.trim();
  regContext.postalCode = removeSpacePostalCode(regContext.postalCode);
  regContext.theme = theme;
  return fetch(`${MICROSERVICE_URL}/register/create-account`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(regContext)
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const digitalCreateAccount = async (regContext: RegInfoType, email: string, fName:string, lName: string): Promise<Response> => {
  // trim some fields before sending data
  regContext.fName = regContext.fName.trim();
  regContext.lName = regContext.lName.trim();
  const data = {
    "email": email,
    "firstName": fName,
    "lastName": lName
  }
  return await fetch(`${MICROSERVICE_URL}/register/digital-card`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((r: Response) => handleServiceUnavailable(r));
};


/**
 * Sends an email with a password reset link
 * Returns server response status
 * @param email
 * @param theme
 */
export const sendForgotPasswordEmail = (email: string, theme: string): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email.trim(), banner: theme })
  }).then((r: Response) => handleServiceUnavailable(r));
};
/**
 * Complete the forgot password email, and update the password
 * @param jwt
 * @param pass
 */
export const forgotPasswordChange = (
  jwt: string,
  pass: string
): Promise<Response> => {
  return fetch(`${MICROSERVICE_URL}/register/complete-forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ jwt: jwt, password: pass })
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const verifyEmailExists = async (email: string) => {
  return await fetch(`${MICROSERVICE_URL}/register/email-available`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email.trim() })
  }).then((r: Response) => handleServiceUnavailable(r));
}
